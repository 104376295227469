import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { UploadStructure } from '../../../../property-editor/editors/upload/upload.structure';
import { AXFFormatService } from '../../../../services/format.service';
import { AXFUrlResolverService } from '../../../../services/url-resolver.service';
import { AXFWidgetView } from '../../../config/widget';

@Component({
  templateUrl: './image-widget.print.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: { role: 'image-box' },
})
export class AXFImageWidgetPrint extends AXFWidgetView {
  private _value!: UploadStructure;
  private dataSubscription: Subscription | undefined;

  alt!: string;
  boxStyle: any;

  @Input()
  public get value(): UploadStructure {
    return this._value;
  }
  public set value(v: UploadStructure) {
    if (JSON.stringify(v) !== JSON.stringify(this._value)) {
      this._value = v;

      this.dataService?.setValue(this.getPath() as string, v);
      this.cdr.detectChanges();
    }
  }

  constructor(
    private hostElement: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
    private formatService: AXFFormatService,
    private resolverService: AXFUrlResolverService // private hostElement: ElementRef<HTMLElement>,
  ) {
    super();

    this.dataSubscription = this.dataService?.onChange.subscribe((data) => {
      if (this.value.srcData.startsWith('[$')) {
        let newFileId = this.formatService.format(this.value.srcData, this);
        // console.log('srcData called', this.value.srcData);
        // console.log('newFileId', newFileId);
        // console.log('data', this.resolveProperty);

        if (newFileId.includes('/')) {
          const arr = newFileId.split('/');
          newFileId = arr[arr.length - 1];
        }
        if (newFileId) {
          // console.log('newFileId called', newFileId);
          this.resolverService.resolve(newFileId).then((resolve) => {
            const _url = resolve;
            const linkSource = _url['changingThisBreaksApplicationSecurity'];
            this.hostElement.nativeElement.children[0]['src'] = linkSource;
            // this.value.srcData = linkSource;
          });
        } else {
          this.hostElement.nativeElement.children[0]['src'] =
            '../../../../../../assets/images/noimage.png';
        }
      }
    });
  }

  override onRender(): void {
    this.cdr.detectChanges();
  }

  override ngOnInit() {
    super.ngOnInit();
    if (this.value.srcData && this.value.srcData.match(/\[(.*?)\]/g)) {
      let imagurl = this.formatService.format(this.value.srcData, this);
      if (imagurl.includes('base64')) {
        this.value.srcData = imagurl;
        // this.cdr.markForCheck();
      }
    }
  }

  override ngAfterViewInit() {
    super.ngAfterViewInit();
    const el: HTMLElement = this.hostElement.nativeElement as HTMLElement;
    if (
      el &&
      el.children.length > 0 &&
      (this as any)['tag'] &&
      (this as any)['tag'] != ''
    ) {
      this.hostElement.nativeElement.firstElementChild.setAttribute(
        'role',
        (this as any)['tag']
      );
    }
  }
}
