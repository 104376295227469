import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AXFWidgetService } from '../../../services/widget.service';
import {
  AXF_DATA_TYPE_PROPERTY,
  AXF_NAME_PROPERTY,
  AXF_TAG_PROPERTY,
  AXF_VALIDATION_PROPERTY,
  AXF_VISIBLE_PROPERTY,
} from '../../config/general-properties';
import { AXFWidgetSharedModule } from '../../shared/shared.module';

import { ACoreXUIModule } from '@acorex/ui';
import { AngularImageViewerModule } from '@hreimer/angular-image-viewer';
import { AXFImageInputWidgetDesigner } from './designer/image-input-widget.designer';
import { ImageModalPage } from './imagemodal.page';
import { AXFImageInputWidgetPrint } from './print/image-input-widget.print';
import { AXFImageInputWidgetView } from './view/image-input-widget.view';

export const COMPONENTS = [
  AXFImageInputWidgetDesigner,
  AXFImageInputWidgetPrint,
  AXFImageInputWidgetView,
  ImageModalPage,
];

@NgModule({
  declarations: [...COMPONENTS],
  imports: [
    CommonModule,
    ACoreXUIModule,
    AXFWidgetSharedModule,
    AngularImageViewerModule,
  ],
  exports: [...COMPONENTS],
  entryComponents: [...COMPONENTS],
  providers: [],
})
export class AXFImageInputWidgetModule {
  constructor(service: AXFWidgetService) {
    service.register({
      title: 'Image Uploader',
      hint: 'Upload image from device or camera',
      icon: 'far fa-image',
      category: 'Editors',
      visible: true,
      name: 'image-input',
      designerClass: AXFImageInputWidgetDesigner,
      printClass: AXFImageInputWidgetPrint,
      viewClass: AXFImageInputWidgetView,
      options: {
        height: 150,
        width: 150,
        dataType: 'object',
      },
      properties: [
        {
          name: 'width',
          category: 'General',
          defaultValue: '',
          title: 'Width(px)',
          editor: 'TextEditor',
        },
        {
          name: 'height',
          category: 'General',
          defaultValue: '',
          title: 'Height(px)',
          editor: 'TextEditor',
        },
        AXF_VISIBLE_PROPERTY,
        AXF_NAME_PROPERTY,
        AXF_DATA_TYPE_PROPERTY,
        AXF_VALIDATION_PROPERTY,
        AXF_TAG_PROPERTY,
      ],
    });
  }
}
